<template>
  <div class="modal offer-modal ready-modal-products">
    <div class="overlay"  @click="$parent.closeAddExistingIslandModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeAddExistingIslandModal" src="./../assets/close.svg"/>
      <div class="container">
        <div class="form-wrapper" v-if="!$parent.successMessage">
          <div class="title">{{ $t('Add existing island')}}</div>
          <label>
            <div class="desc"><span class="red">*</span> {{ $t('Host')}}:</div>
            <input type="text" :placeholder="$t('Host')" v-model="host"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> {{ $t('Port')}}:</div>
            <input type="text" :placeholder="$t('Port')" v-model="port"/>
          </label>
          <button :class="['button', {'disabled': !port && !host}]" @click="$parent.addExistingIsland">{{ $t('Buy')}}</button>
          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{$parent.error}}</div>
          </transition>
        </div>
        <transition name="fade">
          <div v-if="$parent.successMessage">
            <div class="title green">{{$parent.successMessage}}</div>
            <button class="button" @click="$parent.closeAddExistingIslandModal">{{ $t('Ok')}}</button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModalProducts',
  props: [],
  components: {
  },
  data: function() {
    return {
      host: '',
      port: ''
    }
  },
  watch: {
    host() {
      this.$emit('hostChange', this.host)
    },
    port() {
      this.$emit('portChange', this.port)
    }
  },
  methods: {
   
  }
}
</script>
<template>
  <div class="modal offer-modal top-modal">
    <div class="overlay" @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeTopUpModal" src="./../assets/close.svg" alt="Close"/>
      <div class="container">
        <div class="form-wrapper form-wrapper-anount" v-if="amountStep">
          <div class="title">{{ $t('Enter amount') }}</div>
          <label>
            <div class="desc">{{ $t('Amount') }}</div>
            <input type="text" :placeholder="$t('Amount')" v-model="amount"/>
            <div class="title">{{$parent.currency}}</div>
          </label>
          <button class="button" @click="nextStep">{{ $t('Next') }}</button>
        </div>
        <div class="form-wrapper" v-if="!amountStep">
          <div class="title">{{ $t('Enter contact information') }}</div>
          <label>
            <div class="desc"><span class="red">*</span> {{ $t('First name') }}:</div>
            <input type="text" :placeholder="$t('First name')" v-model="name"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> {{ $t('E-mail') }}:</div>
            <input type="email" :placeholder="$t('E-mail')" v-model="email"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> {{ $t('Last name') }}:</div>
            <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> {{ $t('Phone') }}:</div>
            <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
          </label>
          <label class="input-container">
            <div class="desc">*{{ $t('Country') }}:</div>
            <select v-model="country">
              <option disabled selected value="">{{ $t('Country') }}</option>
              <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{ item.title }}</option>
            </select>
          </label>
          <label>
            <div class="desc">*{{ $t('City') }}:</div>
            <input type="text" :placeholder="$t('City')" v-model="city"/>
          </label>
          <label>
            <div class="desc">*{{ $t('Address') }}:</div>
            <input type="text" :placeholder="$t('Address')" v-model="address"/>
          </label>
          <label>
            <div class="desc">*{{ $t('Post Code') }}:</div>
            <input type="text" :placeholder="$t('Post Code')" v-model="postCode"/>
          </label>
          <label class="chekbox-label">
            <div class="chekbox-label-wrapper">
              <input type="checkbox" name="terms" v-model="terms"/>
              <div class="checkbox"></div>
              <span class="title">{{ $t('I agree with') }}</span>
              <a @click="goToPrivacy()" class="title">{{ $t('privacy policy') }} </a>
              <span class="title">{{ $t('and') }}</span>
              <a @click="goToTerms()" class="title">{{ $t('terms and conditions') }}</a>
            </div>
          </label>
          <div class="total">
            <div class="title price">{{ $t('Top Up') }}: {{ amount }} {{ $parent.currency }}</div>
          </div>
          <div v-if="errorMessage" class="error-desc desc red">{{ errorMessage }}</div>
          <div class="button-container">
            <button @click="submit" :class="['button', {'disabled': !requiredFieldsAreFilled}]">{{ $t('Debit/Credit Card') }}</button>
            <img class="img" src="./../assets/multibanco.svg" alt="Multibanco"/>
            <img class="img" src="./../assets/mbway.svg" alt="MBWay"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Select2 from 'vue3-select2-component';
export default {
  name: 'TopUpModal',
  props: [],
  components: {
    // Select2
  },
  data: function() {
    return {
      amount: '',
      amountStep: true,
      email: '',
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
      errorMessage: null
    }
  },
  mounted() {
    this.getCounties();
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignIn();
      }
    })
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.amount && this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    selectCountry(event) {
      this.country = event;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "amount": this.amount,
        "country": this.country.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode
      }
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        } else if (res.response.status === 400) {
          if (res.response.data.message) {
            this.errorMessage = res.response.data.message
          }
        }
      })
    },
    nextStep() {
      if (this.amount) {
        this.amountStep = false
      }
      
    },
    goToTerms() {
      this.$parent.closeTopUpModal();
      this.$parent.goToTerms();
    },
    goToPrivacy() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPrivacy();
    }
  }
}
</script>
<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <div class="left">
            <a href="index.html" class="logo">
              <div class="title">cubecrafterclub<span>.com</span></div>
            </a>
          </div>
          <div class="center">
            <ul class="nav">
              <template  v-for="item in $parent.nav">
                <li class="nav__item" :key="item.title">
                  <router-link :to="item.path">
                    {{ $t(item.title) }}
                  </router-link>
                </li>
              </template>
            </ul>
            <ul class="nav">
              <template  v-for="item in $parent.textPageList">
                <li class="nav__item" :key="item.id">
                  <a @click="$parent.goToPage(item)">
                    {{ $t(item.title) }}
                  </a>
                </li>
              </template>
              <li class="nav__item">
                <a :href="`mailto:` + $parent.settings.support_email">{{ $parent.settings.support_email }}</a>
              </li>
            
            </ul>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item">
                <img src="./../assets/visa.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/mc_symbol.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/3ds.png" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/multibanco.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/mbway.svg" class="img"/>
              </li>
            </ul>
            <ul class="list socail-list">
              <li v-for="link in $parent.socialLinks" class="item" :key="link.img">
                <a :href="link.link">
                  <img :src="link.img" class="img"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright-section">
        <div class="wrapper">
          <div class="desc year">
            <div v-html="$parent.settings.requisites" >
            </div>
          </div>
          <div v-if="$parent.settings.copyright" class="desc copyright-desc">
            © {{ (new Date()).getFullYear() }} {{ $parent.settings.copyright }}
          </div>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		
	},
	mounted() {
    
  }
}
</script>

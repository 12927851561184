<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">
            {{$t('Profile')}}
          </div>
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper left">
                <div class="top">
                  <div class="icon">
                    <img src="./../assets/user.svg" class="img"/>
                  </div>
                  <div class="text">
                    <div class="title">{{name}} {{surname}}</div>
                    <div class="desc">{{email}}</div>
                  </div>
                </div>
                <div class="nav">
                  <div class="item">
                    <router-link class="desc" to="/profile/my-islands">{{$t('My islands')}}</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/deposit-history">{{$t('Deposit history')}}</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/order-history">{{$t('Order history')}}</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/buy-island">{{$t('Buy island')}}</router-link>
                  </div>
                  <div class="item">
                    <a class="desc" @click="$parent.logout">{{$t('Log out')}}</a>
                  </div>
                </div>
                <div class="balance">
                  <div class="title"><b>{{balance}} {{$parent.currency}}</b></div>
                  <button class="button" @click="$parent.openTopUpModal">{{$t('Top up')}}</button>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy"  v-if="$route.params.content == 'deposit-history'">
                <div class="title">{{$t('Deposit history')}}</div>
                <div class="table-container">
                  <table>
                    <thead>
                    <tr>
                      <td class="title">{{$t('Date')}}</td>
                      <td class="title">{{$t('Status')}}</td>
                      <td class="title">{{$t('Total')}}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                      <td class="desc">{{$parent.formatDate(item.created_at)}}</td>
                      <td class="desc">{{item.status}}</td>
                      <td class="desc">{{item.sum}} {{$parent.currency}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy"  v-if="$route.params.content == 'order-history'">
                <div class="title">{{$t('Order history')}}</div>
                <div class="table-container">
                  <table class="order-table">
                    <thead>
                    <tr>
                      <td class="title">{{$t('Date')}}</td>
                      <td class="title">{{$t('Title')}}</td>
                      <td class="title">{{$t('Status')}}</td>
                      <td class="title">{{$t('Total')}}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                      <td class="desc">{{$parent.formatDate(item.created_at)}}</td>
                      <td class="desc">{{item.title}}</td>
                      <td class="desc">{{item.status}}</td>
                      <td class="desc">{{item.sum}} {{$parent.currency}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy"  v-if="$route.params.content == 'buy-island'">
                <div class="title">{{$t('Buy island')}}</div>
                <div class="list">
                  <div :class="['item', {'active': $parent.islandToBuy.size === item.size}]" @click="$emit('changeIslandToBuy', item)" v-for="(item, i) in $parent.islands" :key="i">
                    <ProductCard :item="item" :currency="$parent.currency"/>
                  </div>
                </div>
                <div class="price title">{{$t('TOTAL')}}: {{$parent.islandToBuy.price}} {{$parent.currency}}</div>
                <div @click="$parent.openReadyModal" class="button">{{$t('Buy')}}</div>
              </div>
              <div class="wrapper right price-block my-islands" v-if="$route.params.content == 'my-islands'">
                <div class="title" v-if="!$parent.myIslands.length">{{$t("You don't have any islands yet! Buy one, or add an existing from your friends!")}}</div>
                <div class="title" v-if="$parent.myIslands.length">{{$t('My islands')}}</div>
                <div class="list" v-if="$parent.myIslands.length">
                  <div class="item" @click="buyOptions(item)" v-for="(item, i) in $parent.myIslands" :key="i">
                    <ProductCard :item="item" :currency="$parent.currency"/>
                  </div>
                </div>
                <div class="buttons">
                  <button class="button" @click="$parent.openAddExistingIslandModal">{{$t('Add existing island')}}</button>
                  <router-link to="/profile/buy-island" class="button">{{$t('Buy island')}}</router-link>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy"  v-if="$route.params.content == 'buy-options'">
                {{ $parent.islandToBuyOptions }}
                <router-link to="/profile/my-islands" class="back"></router-link>
                <div class="title">{{$t('Buy options')}}</div>
                <div v-clipboard:copy="$parent.islandToBuyOptions.host + ':' + $parent.islandToBuyOptions.port" v-if="$parent.islandToBuyOptions && $parent.islandToBuyOptions.host" class="island-ip">
                  {{ $parent.islandToBuyOptions.host + ':' + $parent.islandToBuyOptions.port  }}
                </div>
                <div class="checkbox-list" v-if="$parent.chosenIslandProducts">
                  <template v-for="(item, i) in $parent.chosenIslandProducts" >
                    <label class="chekbox-label-wrapper purchased" v-if="item.purchased" :key="i">
                      <input :value="item" :id="item.title" type="checkbox" v-model="optionsToBuy"/>
                      <div class="text">
                        <div class="checkbox"></div>
                        <img :src="$parent.imgDomain + item.image_url" class="img"/>
                        <div class="desc">{{item.title}}</div>
                      </div>
                      <div class="price title">{{item.price}} {{$parent.currency}}</div>
                    </label>
                  </template>
                  <template v-for="(item, i) in $parent.chosenIslandProducts">
                    <label class="chekbox-label-wrapper" v-if="!item.purchased" :key="i">
                      <input :value="item" :id="item.title" type="checkbox" v-model="optionsToBuy"/>
                      <div class="text">
                        <div class="checkbox"></div>
                        <img :src="$parent.imgDomain + item.image_url" class="img"/>
                        <div class="desc">{{item.title}}</div>
                      </div>
                      <div class="price title">{{item.price}} {{$parent.currency}}</div>
                    </label>
                  </template>
                </div>
                <div v-if="optionsTotal" class="price title">{{$t('TOTAL')}}: {{optionsTotal}} {{$parent.currency}}</div>
                <button :class="['button', {'disabled': !optionsToBuy.length}]"  @click="$parent.openReadyModalProducts">{{$t('Buy')}}</button>
              </div>
            </div>
          </div>
          <div class="contact-form modal">
            <div class="contact-form-wrapper">
              <div class="title">{{$t('Contact information')}}</div>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('First name')}}:</div>
                <input type="text" :placeholder="$t('First name')" v-model="name"/>
              </label>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('E-mail')}}:</div>
                <input type="email" :placeholder="$t('E-mail')" v-model="email"/>
              </label>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('Last name')}}:</div>
                <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
              </label>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('Phone')}}:</div>
                <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
              </label>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('Nickname on Minecraft server')}}:</div>
                <input type="text" :placeholder="$t('Nickname on Minecraft server')" v-model="nickname"/>
              </label>
              <div class="button-container button-container-first">
                <button class="button" @click="updateProfileData">
                  <span>{{$t('Update')}}</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                </transition>
              </div>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('Current password')}}:</div>
                <input type="text" :placeholder="$t('Current password')" v-model="curPass"/>
              </label>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('New password')}}:</div>
                <input type="text" :placeholder="$t('New password')" v-model="newPass"/>
              </label>
              <label>
                <div class="desc"><span class="red">*</span> {{$t('Confirm password')}}:</div>
                <input type="text" :placeholder="$t('Confirm password')" v-model="repeatPass"/>
              </label>
              <div class="button-container">
                <button class="button" @click="updatePassword">
                  <span>{{$t('Update')}}</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import ProductCard from '../components/ProductCard.vue';
export default {
  name: 'Profile',
  props: ['optionsToBuyFinal', 'totalBalance', 'zeroOptionsChosen'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      balance: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      optionsToBuy: [],
      optionsTotal: null
      
    }
  },
  watch: {
    optionsToBuy() {
      this.optionsTotal = 0;
      for (let i = 0; i < this.optionsToBuy.length; i++) {
       this.optionsTotal +=  parseFloat(this.optionsToBuy[i].price)
      }

      this.optionsTotal = this.optionsTotal.toFixed(2)

      console.log('here:', this.optionsTotal)

      this.$emit('optionsTotalPriceChange', this.optionsTotal)
      this.$emit('optionsToBuyFinalChange', this.optionsToBuy)
    },
    optionsToBuyFinal() {
      if (!this.optionsToBuyFinal.length) {
        this.optionsTotal = '';
      }  
    },
    zeroOptionsChosen() {
      if (this.zeroOptionsChosen) {
        this.optionsToBuy = [];
      }  
    },
    totalBalance() {
      this.balance = this.totalBalance;
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.balance = res.data.balance;
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.nickname = res.data.nickname;
    })
    .catch(() => {
      
    })
  },
  methods: {
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        "nickname": this.nickname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    buyOptions(item) {
      this.$emit('changeIslandToBuyOptions', item);
      this.$router.push({path: '/profile/buy-options'});
    }
  }
}
</script>
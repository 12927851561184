<template>
  <div class="item-wrapper">
    <div class="title">{{ item.title }}</div>
    <div class="desc small" v-if="item.host">{{ item.host }} ({{ item.port }}) </div>
    <div class="desc small"></div>
    <div class="desc">{{ item.dimensions }}</div>
    <div class="preview">
      <img :src="imgDomain  + item.image_url" class="img"/>
    </div>
    <div class="price title big">
      <span>{{ item.price }}<span class="currency">{{ currency }}</span></span>
    </div>
    <router-link to="/registration" class="button" v-if="!$parent.isAuth">
      <span>{{ $t('Buy a Private Island') }}</span>
    </router-link>
    <router-link to="/profile/buy-island" class="button" v-else>
      <span>{{ $t('Buy a Private Island') }}</span>
    </router-link>
  </div>
</template>


<script>

export default {
	name: 'ProductCard',
	props: ['item', 'currency'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		
	},
}
</script>

<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="title big">
            <div class="yellow">{{$t('Exclusive')}}</div>
            <div class="blue">{{$t('destination')}}</div>
          </div>
          <div class="left">
            <img src="./../assets/hero.png" class="img"/>
          </div>
          <div class="right">
            <div class="title">{{$t('for purchasing your own private island in Minecraft!')}}</div>
            <button @click="$emit('scrollToPrice')" class="button">{{$t('Buy a Private Island')}}</button>
            <div class="desc">
              {{$t('Our website is designed to offer a unique, luxurious and safe experience for all our customers.')}}
            </div>
          </div>
        </div>
      </div>
      <div class="section section-2">
        <div class="wrapper">
          <div class="left">
            <div class="title big">{{$t('Why Buy a Private Island in Minecraft?')}}</div>
          </div>
          <ul class="list">
            <li class="item desc">
              {{$t('Imagine owning a piece of paradise, a place where you can build and create your own world without any limitations.')}}
            </li>
            <li class="item desc">
              {{$t('Our private islands offer the perfect getaway from the hustle and bustle of the crowded Minecraft servers. You can enjoy the privacy and security of your own island, free from trolls and griefers.')}}
            </li>
            <li class="item desc">
              {{$t('At Private Island Sales, we offer a wide range of private islands to choose from. Whether you are looking for a tropical paradise, a winter wonderland or a floating island in the sky, we have got you covered!')}}
            </li>
          </ul>
        </div>
      </div>
      <img src="./../assets/divider.png" class="divider"/>
      <div class="section section-3">
        <div class="wrapper">
          <img src="./../assets/guys.png" class="img"/>
          <div class="title big">{{$t('Safe and Secure')}}</div>
          <div class="desc">
            {{$t('Our website is committed to protecting your personal information. We use state-of-the-art security measures to ensure that your data is safe and secure. Your privacy is important to us, and we will never share your information with third parties.')}}
          </div>
        </div>
      </div>
      <div class="section section-4">
        <img src="./../assets/divider2.png" class="divider"/>
        <div class="section-4-wrapper">
          <div class="wrapper">
            <div class="why-us">
              <div class="left">
                <div class="title big">{{$t('Why Choose Us?')}}</div>
              </div>
              <div class="center">
                <div class="desc">
                  {{$t('We are the leading provider of private islands in Minecraft, and we take pride in offering our customers the highest level of service and support. Our team of experts is always available to answer your questions and provide you with the information you need to make the right decision.')}}
                  <br/><br/>
                  {{$t('If you are looking for a unique and luxurious experience in Minecraft, look no further than Private Island Sales. Explore our website today to discover our wide range of private islands and start building your own paradise!')}}
                </div>
                <img src="./../assets/gold.png" class="img"/>
              </div>
              <img src="./../assets/guy.png" class="guy"/>
            </div>
            <div class="price-block" id="price">
              <div class="title big">{{$t('Our Price:')}}</div>
              <div class="list" v-if="$parent.islands">
                <div class="item" v-for="(item, i) in $parent.islands" :key="i">
                  <ProductCard :item="item" :currency="currency"/>
                </div>
              </div>
            </div>
            <div class="options-block" v-if="$parent.products">
              <div class="title big">{{$t('Options:')}}</div>
              <div class="list solo">
                <div class="item">
                  <OptionCard :item="$parent.products[0]" :currency="currency"/>
                </div>
              </div>
              <div class="list">
                <div class="item" v-for="(item, i) in $parent.products.slice(0, 13)" :key="i">
                  <OptionCard :item="item" :currency="currency"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import OptionCard from '../components/OptionCard.vue';


export default {
  name: 'Home',
  props: ['currency', 'isAuth'],
  components: {
    ProductCard,
    OptionCard
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
  }
}
</script>
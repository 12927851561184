<template>
  <main class="main page-inside faq-page text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">
            {{$t('FAQ')}}
          </div>
          <div class="content">
            <div class="title">{{$t('What is Cubecrafterclub.com?')}}</div>
            <div class="desc">
              {{$t('Cubecrafterclub.com is a website where you can purchase Minecraft islands with various additional options such as vegetation, structures, water, climate, fauna, transportation, lighting, and custom skins.')}}
            </div>
            <div class="title">{{$t('What are the sizes of the islands that can be purchased?')}}</div>
            <div class="desc">
              {{$t('We offer three island sizes: a small size of 30x30 blocks, a medium size of 70x70 blocks, and a large size of 140x140 blocks.')}}
            </div>
            <div class="title">{{$t('How much does an island cost and how can I purchase it?')}}</div>
            <div class="desc">
              {{$t('A small island costs 100 euros, a medium island costs 240 euros, and a large island costs 480 euros. You can purchase an island by selecting the desired size and options on our website and making an online payment.')}}
            </div>
            <div class="title">{{$t('What options are available when purchasing an island?')}}</div>
            <div class="desc">
              {{$t('We offer additional options for your island such as vegetation, structures, water, climate, fauna, transportation, lighting, and custom mods.')}}
            </div>
            <div class="title">{{$t('Can I change the options on my island after purchase?')}}</div>
            <div class="desc">
              {{$t('Yes, you can change the options on your island at any time. In your personal account in the section «my islands».')}}
            </div>
            <div class="title">{{$t('How can I connect to my island in Minecraft?')}}</div>
            <div class="desc">
              {{$t('Upon purchasing an island, we will provide you with a host and port that you can use to connect to your island through the original Minecraft game.')}}
            </div>
            <div class="title">{{$t('How can I get support if I have any issues with my island?')}}</div>
            <div class="desc">
              {{$t('You can contact our support team through the contact form on our website or via email. We will be happy to assist you with any questions or issues you may have.')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'Faq',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
   
  }
}
</script>
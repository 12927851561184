<template>
  <main class="main page-inside text-page contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">
            {{$t('Contact Us')}}
          </div>
          <div class="content">
            <label>
              <div class="desc">{{$t('Name')}}:</div>
              <input type="text" :placeholder="$t('Name')" v-model="name"/>
            </label>
            <label>
              <div class="desc">{{$t('Email')}}:</div>
              <input type="text" :placeholder="$t('Email')" v-model="email"/>
            </label>
            <label>
              <div class="desc">{{$t('Message')}}</div>
              <textarea :placeholder="$t('Message')" v-model="message"></textarea>
            </label>
            <button class="button" @click="submit">{{$t('Send')}}</button>
            <transition name="fade">
              <div v-if="$parent.successMessage" class="title green">{{$parent.successMessage}}</div>
            </transition>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>
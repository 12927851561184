<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <div class="title">cubecrafterclub<span>.com</span></div>
        </router-link>
      </div>
      <ul class="nav">
        <template v-for="item in $parent.nav">
          <li class="nav__item" :key="item.title">
            <router-link class="desc" :to="item.path">
              {{ $t(item.title) }}
            </router-link>
          </li>
        </template>
      </ul>
      <div class="right">
        <router-link v-if="$parent.isAuth" to="/profile/my-islands" class="link-container">
          <span class="desc">{{$parent.name}}</span>
          <div class="icon">
            <img src="./../assets/user.svg" class="img"/>
          </div>
        </router-link>
        <div v-else class="link-container">
          <div class="desc hover-link" @click="openSignUp">
            <span>{{$t('Sign Up')}}</span>
          </div>
          <div class="button hover-link" @click="openSignIn">
            <span>{{$t('Login')}}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>


<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    }
	}
}
</script>
